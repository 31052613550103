// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-creation-tsx": () => import("/opt/build/repo/src/pages/creation.tsx" /* webpackChunkName: "component---src-pages-creation-tsx" */),
  "component---src-pages-demo-feedback-tsx": () => import("/opt/build/repo/src/pages/demo_feedback.tsx" /* webpackChunkName: "component---src-pages-demo-feedback-tsx" */),
  "component---src-pages-demo-follow-tsx": () => import("/opt/build/repo/src/pages/demo_follow.tsx" /* webpackChunkName: "component---src-pages-demo-follow-tsx" */),
  "component---src-pages-demo-tsx": () => import("/opt/build/repo/src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-features-tsx": () => import("/opt/build/repo/src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-features-academy-tsx": () => import("/opt/build/repo/src/pages/features/academy.tsx" /* webpackChunkName: "component---src-pages-features-academy-tsx" */),
  "component---src-pages-features-challenges-tsx": () => import("/opt/build/repo/src/pages/features/challenges.tsx" /* webpackChunkName: "component---src-pages-features-challenges-tsx" */),
  "component---src-pages-features-dashboard-tsx": () => import("/opt/build/repo/src/pages/features/dashboard.tsx" /* webpackChunkName: "component---src-pages-features-dashboard-tsx" */),
  "component---src-pages-features-ideabox-tsx": () => import("/opt/build/repo/src/pages/features/ideabox.tsx" /* webpackChunkName: "component---src-pages-features-ideabox-tsx" */),
  "component---src-pages-features-intra-tsx": () => import("/opt/build/repo/src/pages/features/intra.tsx" /* webpackChunkName: "component---src-pages-features-intra-tsx" */),
  "component---src-pages-features-lab-tsx": () => import("/opt/build/repo/src/pages/features/lab.tsx" /* webpackChunkName: "component---src-pages-features-lab-tsx" */),
  "component---src-pages-features-openinno-tsx": () => import("/opt/build/repo/src/pages/features/openinno.tsx" /* webpackChunkName: "component---src-pages-features-openinno-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("/opt/build/repo/src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-resources-tsx": () => import("/opt/build/repo/src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

